import React, { useState, useEffect } from "react"
import { PageProps } from "gatsby"

import queryString from "query-string"


import { Layout } from "@/components/layout"
import SEO from "@/components/seo"
import { Form } from "@/components/form"
import { Article } from "@/components/article"

const BlogDraft: React.VFC<PageProps<GatsbyTypes.BlogPostQuery>> = ({
  location,
}) => {
  const { contentId, draftKey } = queryString.parse(location.search)
  const [data, setData] = useState<GatsbyTypes.BlogPostQuery | null>(null)
  const [url, setUrl] = useState("")

  useEffect(() => {
    setUrl(location.href)
  }, [])

  useEffect(() => {
    const draftFetch = async () => {
      try {
        const response = await fetch(
          `https://oh-tam.microcms.io/api/v1/blog/${contentId}?draftKey=${draftKey}`,
          {
            headers: {
              "X-API-KEY": "6d86dee2-f111-4118-877c-db4bd3d4db87",
            },
          }
        )
        const data = await response.json()
        setData({ microcmsBlog: data })
      } catch (error) {
        console.log(error)
      }
    }
    draftFetch()
  }, [])

  if (data === null) {
    return null
  }

  return (
    <Layout>
      <SEO
        title={data.microcmsBlog?.title}
        description={data.microcmsBlog?.excerpt}
        image={data.microcmsBlog?.img?.url}
        url={url}
      />

      <Article microcmsBlog={data.microcmsBlog} />
      <Form />
    </Layout>
  )
}

export default BlogDraft
